.input {
  @apply block min-w-[100%] font-suzukiRegular h-[50px] leading-[50px] pl-[16px] rounded-none outline-none px-2 text-[14px] border-[1px] border-[#C0C0C0];

  /* Default: max width for mobile (100%) */
  @apply max-w-[100%];

  /* For screens larger than sm (640px), max width 120% */
  @apply sm:max-w-[125%];

  /* For screens larger than md (768px), max width 150% */
  @apply md:max-w-[210%];

  /* For screens larger than lg (1024px), max width 175% */
  @apply lg:max-w-[210%];

  /* For screens larger than xl (1280px), max width 200% */
  @apply xl:max-w-[210%];
}

.dateinput {
  @apply pt-[150px] block min-w-[100%] font-suzukiRegular max-h-[50px] leading-[50px] px-3 rounded-none outline-none  text-[14px] border-[1px] border-[#C0C0C0];
}

/* .input[type="date"] {
  @apply pt-3 text-left;
} */

.input[type="date"] {
  @apply pt-1 text-left;
}

@supports (-webkit-touch-callout: none) {
  .input[type="date"] {
    @apply text-left !important;
    @apply pt-3  min-w-[95%] max-w-[95%];
  }
  .inputError {
    @apply min-w-[95%];
  }

  .input:focus {
    border-color: #001151;
    text-align: start !important;
  }

  .input:focus-visible {
    outline: none;
    text-align: start !important;
  }
}

.inputError {
  @apply block w-[100%] h-[50px] rounded-none outline-none px-2 pl-[16px] text-[14px] border-[2px] border-[#FF9494];
}

.input:focus {
  border-color: #001151;
  text-align: start;
}

.input:focus-visible {
  outline: none;
  text-align: start;
}

.textarea {
  @apply block w-[100%] font-suzukiRegular py-2 pl-[16px] rounded-none outline-none px-2 text-[14px] border-[1px] border-[#C0C0C0];
}

.textareaError {
  @apply block w-[100%] h-[50px] rounded-none outline-none px-2 text-[14px] border-[2px] border-[#FF9494];
}

.textarea:focus {
  border-color: #001151;
}

.label {
  @apply block mb-[6px] text-[14px] text-start leading-[21px] font-[400] text-[#000] tracking-[0.02em] font-['SuzukiRegular'];
}

.submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[105px] h-[32px] rounded-[90px] text-[13px];
  background-color: #001151 !important;
}

.select__container select {
  @apply text-[#000] font-suzukiRegular;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 15px;
  background-image: url("../../../public/Vector.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 10px;
  background-color: transparent;
}

.title {
  @apply text-[24px] leading-[30px] font-['SuzukiBold'] pr-8;
}

.error {
  @apply text-[#FF0000] text-[12px];
}

.title {
  @apply text-[24px] leading-[30px] font-['SuzukiBold'] pr-8;
}
